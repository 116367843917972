<template>
  <BaseModal ref="modal" title="Nieuw dossier" max-width="tw-max-w-5xl">
    <FormulateForm
      #default="{ isLoading }"
      v-model="values"
      name="propertyCreateForm"
      invalid-message="Gelieve de verplichte velden correct in te vullen."
      @submit="submit"
    >
      <div class="tw-mb-8">
        <h2>Algemene informatie</h2>
        <div class="tw-my-4 tw-grid md:tw-grid-cols-3 tw-gap-4">
          <FormulateInput
            v-if="project"
            type="text"
            name="reference"
            label="Referentie"
            placeholder="Referentie"
            validation="bail|required:trim|validatePropertyReference"
            outer-class="tw-m-0 tw-col-span-3"
          />
          <FormulateInput
            v-if="project"
            type="select"
            name="status"
            label="Status"
            :options="[
              { value: 2, label: 'Te koop' },
              { value: 3, label: 'Te huur' },
              { value: 4, label: 'Over te nemen' },
              { value: '-', label: '----', disabled: true },
              { value: 10, label: 'Verkocht' },
              { value: 11, label: 'Verhuurd' },
              { value: 12, label: 'Overgenomen' }
            ]"
            validation="required"
            outer-class="tw-m-0"
          />
          <FormulateInput
            v-else
            type="select"
            name="transaction_type"
            label="Transactietype"
            :options="PROPERTY_TRANSACTION_TYPES"
            validation="required"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="select"
            name="niche"
            label="Niche"
            :options="NICHES"
            validation="required"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="autocomplete"
            auto-complete-type="office"
            :multiple="true"
            name="offices"
            label="Kantoor"
            placeholder="Selecteer kantoren"
            validation="required"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="autocomplete"
            auto-complete-type="collaborator"
            :multiple="true"
            name="collaborators"
            label="Makelaar"
            placeholder="Selecteer makelaars"
            validation="required"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="autocomplete"
            auto-complete-type="contact"
            name="owner"
            label="Eigenaar"
            placeholder="Selecteer eigenaar"
            validation="required"
            outer-class="tw-m-0"
          >
            <template #label="{ label, id, classes }">
              <div class="tw-flex tw-justify-between">
                <label :for="id" :class="classes.label" v-text="label" class="formulate-label" />
                <button
                  type="button"
                  title="Nieuwe eigenaar"
                  class="link tw-font-semibold"
                  @click="showContactCreateModal"
                >
                  <i class="far fa-user-plus" /> Nieuwe
                </button>
              </div>
            </template>
          </FormulateInput>
        </div>
      </div>

      <div class="tw-mb-8">
        <h2>Pandinformatie</h2>
        <div class="tw-my-4 tw-grid md:tw-grid-cols-3 tw-gap-4">
          <div class="tw-col-span-3 tw-grid md:tw-grid-cols-2 tw-gap-4">
            <FormulateInput
              type="select"
              name="type"
              label="Type"
              :options="PROPERTY_TYPES"
              outer-class="tw-m-0"
            />
            <FormulateInput
              type="select"
              name="region"
              label="Gewest"
              :options="REGIONS"
              outer-class="tw-m-0"
            />
          </div>

          <FormulateInput
            type="autocomplete"
            auto-complete-type="street"
            name="street"
            label="Straat"
            placeholder="Zoek een straat"
            validation="required"
            outer-class="tw-m-0"
            @callback="streetSelected"
          />

          <div class="tw-grid md:tw-grid-cols-2 tw-gap-4">
            <FormulateInput
              type="text"
              name="number"
              label="Huisnummer"
              placeholder="Huisnummer"
              validation="required:trim"
              outer-class="tw-m-0"
            />
            <FormulateInput
              type="text"
              name="box"
              label="Bus"
              placeholder="Bus"
              outer-class="tw-m-0"
            />
          </div>

          <FormulateInput
            type="autocomplete"
            auto-complete-type="city"
            name="city"
            label="Plaats"
            placeholder="Zoek op postcode of plaatsnaam"
            validation="required"
            outer-class="tw-m-0"
          />
        </div>
      </div>

      <div v-if="!isRentalManagement" class="tw-mb-8">
        <h2>Prijs</h2>
        <div class="tw-my-4 tw-grid md:tw-grid-cols-3 tw-gap-4">
          <FormulateInput
            type="checkbox"
            name="publish_price"
            label="Prijs zichtbaarheid"
            outer-class="tw-m-0"
          />
          <template v-if="isSales">
            <FormulateInput
              v-model="isVatSystem"
              type="checkbox"
              name="is_vat_system"
              label="Btw-stelsel"
              outer-class="tw-m-0"
            />
            <FormulateInput
              v-if="isVatSystem"
              type="radio"
              name="vat_rate"
              label="BTW-type:"
              value="21"
              :options="{ 6: '6%', 12: '12%', 21: '21%' }"
              :input-class="['tw-my-0']"
              :wrapper-class="['tw-flex tw-flex-row tw-gap-2 tw-items-center']"
              :element-class="['tw-flex tw-flex-row tw-gap-2 tw-items-center']"
              outer-class="tw-m-0"
            />
          </template>
        </div>

        <div class="tw-my-4 tw-grid md:tw-grid-cols-3 tw-gap-4">
          <template v-if="splitPriceInputs">
            <FormulateInput
              type="number"
              name="price_land_share"
              label="Prijs grondaandeel"
              placeholder="Prijs grondaandeel"
              :validation="splitPriceInputs ? 'bail|landShareOrInfrastructureIsRequired|number|min:0' : 'bail|number|min:0'"
              step="any"
              min="0"
              lang="nl"
              outer-class="tw-m-0"
            />
            <FormulateInput
              type="number"
              name="price_infrastructure"
              label="Prijs constructiewaarde"
              placeholder="Prijs constructiewaarde"
              :validation="splitPriceInputs ? 'bail|landShareOrInfrastructureIsRequired|number|min:0' : 'bail|number|min:0'"
              step="any"
              min="0"
              lang="nl"
              outer-class="tw-m-0"
            />
          </template>
          <FormulateInput
            v-else
            type="number"
            name="price"
            label="Prijs"
            placeholder="Prijs"
            validation="bail|required|number|min:0"
            step="any"
            min="0"
            lang="nl"
            outer-class="tw-m-0"
          />
        </div>
      </div>

      <div>
        <h2>Overige</h2>
        <div class="tw-my-4 tw-grid md:tw-grid-cols-3 tw-gap-4">
          <FormulateInput
            v-if="isSales"
            type="checkbox"
            name="is_investment_property"
            label="Investeringsvastgoed"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="checkbox"
            name="is_luxury"
            label="Luxevastgoed"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="checkbox"
            name="has_development_potential"
            label="Ontwikkelingspotentieel"
            outer-class="tw-m-0"
          />
        </div>
      </div>

      <div class="tw-flex tw-flex-col tw-items-end tw-w-full">
        <FormulateErrors />
        <FormulateInput
          type="submit"
          title="Dossier aanmaken"
          :disabled="isLoading"
          outer-class="tw-my-2"
        >
          <i
            :class="[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-check'
            ]"
          />
          Dossier aanmaken
        </FormulateInput>
      </div>
    </FormulateForm>

    <ContactCreateModal
      ref="contactCreateModal"
      :redirect="false"
      @success="newOwnerCreated"
    />
  </BaseModal>
</template>

<script>
import ContactCreateModal from '@/components/contacts/ContactCreateModal'

import { NICHES, REGIONS, PROPERTY_TRANSACTION_TYPES } from '@/forms/selectOptions'
import { PROPERTY_TYPES } from '@/forms/schemas/portfolio/property/address'

import { createProperty } from '@/services/properties'
import { getProjectOffices, getProjectOwners, getProjectCollaborators } from '@/services/projects'

export default {
  name: 'PropertyCreateModal',
  components: { ContactCreateModal },
  props: {
    project: {
      type: Object,
      default: null
    },
    redirect: {
      // Takes user to the Property's page after creation.
      type: Boolean,
      default: true
    }
  },
  constants: {
    NICHES,
    REGIONS,
    PROPERTY_TYPES,
    PROPERTY_TRANSACTION_TYPES
  },
  data () {
    return {
      values: {},
      isVatSystem: false
    }
  },
  computed: {
    isSales () {
      return [2, 10].includes(parseInt(this.values?.transaction_type)) || [2, 10].includes(parseInt(this.values?.status))
    },
    isRentalManagement () {
      return parseInt(this.values?.transaction_type) === 5
    },
    splitPriceInputs () {
      return this.isSales && this.isVatSystem
    }
  },
  methods: {
    show () {
      this.init(this.project)
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    showContactCreateModal () {
      this.$refs.contactCreateModal.show()
    },
    streetSelected (street) {
      if (street?.city) this.values.city = street.city
      if (street?.number) this.values.number = street.number
    },
    newOwnerCreated (contact) {
      const { type, company_name, first_name, last_name } = contact
      const display_name = type === 'B' ? company_name : `${first_name} ${last_name}`
      this.$set(this.values, 'owner', { display_name, ...contact })
      return contact
    },
    buildReference (project) {
      /* Builds a reference suggestion for a new entity */
      let reference = project.reference
      const referenceTokens = reference.split('-')
      if (referenceTokens.length > 2) {
        referenceTokens.pop()
        reference = referenceTokens.join('-')
      }
      return `${reference}-X`
    },
    async init (project) {
      this.values = { publish_price: true }
      if (!project) return false

      const responses = await Promise.all([
        getProjectOwners(project.id),
        getProjectOffices(project.id),
        getProjectCollaborators({ projectId: project.id })
      ])
      const [owners, offices, collaborators] = responses.map(response => response.data)

      if (owners?.[0]) this.$set(this.values, 'owner', owners[0])
      if (offices?.results) this.$set(this.values, 'offices', offices?.results)
      if (collaborators?.results) this.$set(this.values, 'collaborators', collaborators?.results)
      if (project.reference) this.$set(this.values, 'reference', this.buildReference(project))
      this.$set(this.values, 'project', project.id)
    },
    async submit (data) {
      try {
        const payload = { create_property_extended: true, ...data }
        payload.city = payload.city?.id
        payload.owner = payload.owner?.id
        payload.street = payload.street?.text
        payload.offices = payload.offices.map(office => office.id)
        payload.collaborators = payload.collaborators.map(collaborator => collaborator.id)

        const response = await createProperty(payload)
        const property = response.data
        if (this.redirect) this.$router.push({ name: 'PropertyDetails', params: { id: property?.id } })
        this.$emit('success', property)
        this.hide()
        return response
      } catch (error) {
        console.error(error)
        this.$formulate.handle(error, 'propertyCreateForm')
      }
      return data
    }
  }
}
</script>

<style scoped>
h2 {
  @apply !tw-m-0;
}
</style>
